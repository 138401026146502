import Img from "gatsby-image";
import React from "react";
import { Col } from "react-bootstrap";

const Testimonial = ({
  customerName,
  customerLocation,
  customerLogo,
  description,
}) => {
  return (
    <Col md={3}>
      <div className="border-all-1 border-color-dark-gray text-center m-25px-t m-20px-b white-bg">
        <div className="icon-60 border-radius-50 theme2nd-bg white-color d-inline-block mt-n5">
          <i className="fas fa-quote-left"></i>
        </div>
        <div className="p-25px">
          <Img
            className="max-width-auto"
            fluid={customerLogo.childImageSharp.fluid}
            loading="eager"
            alt={`${customerName} logo`}
          />
          {description}
          <h5 className="h6 m-0px">{customerName}</h5>
          <label className="font-w-600 font-small m-0px">
            {customerLocation}
          </label>
        </div>
      </div>
    </Col>
  );
};

export default Testimonial;