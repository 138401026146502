import React from "react";
import { Col } from "react-bootstrap";

const FeatureBox = ({ headline, description, icon = "fa-bolt" }) => {
  return (
    <Col sm={3}>
      <div
        className="media"
        style={{
          marginBottom: "1em",
          border: "1px solid #ccc",
          padding: "1em",
          borderRadius: "0.5em",
        }}
      >
        <div className="icon-60 gray-bg">
          <i className={`fas ${icon}`}></i>
        </div>
        <div className="media-body">
          <h6>{headline}</h6>
          <p className="p-0px">{description}</p>
        </div>
      </div>
    </Col>
  );
};

export default FeatureBox;